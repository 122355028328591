const SeeOn = () =>{
    return(
        <section className="see_on" style={{ display : 'none' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="see_on_txt text-center">
                <h6>AS SEEN ON</h6>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="see_on_txt see_on_txt_2">
              <h6>IMAGES TO BE PROVIDED</h6>
            </div>
            </div>
          </div>
        </div>
      </section>
    )

}
export default SeeOn;
